import { axGet, axPost } from '../base';

// 获取科室列表
const departmentList = (params) => {
	return axGet('v1/doctors/depts', params);
};
// 获取某个科室下的疾病列表
const deptDiseaseList = (params) => {
	return axGet('v1/doctors/dept-disease', params);
};
// 首页医生推荐列表
const doctorRecommend = (params) => {
	return axGet('v1/doctors/doctor-recommend', params);
};
// 搜索页面 搜索医生
const searchDoctor = (params) => {
	return axGet('v1/doctors/search-doctor', params);
};
// 搜索页面 搜索历史
const searchHistory = (params) => {
	return axGet('v1/doctors/search-history', params);
};
// 医生详情-医生详情
const doctorDetail = (params) => {
	return axGet('v1/doctors/doctor-detail', params);
};
// 医生详情-医生详情-绑定医患关系
const doctorDetailBind = (params) => {
	return axGet('v1/doctors/doctor-detail-bind', params);
};
// 医生详情-用户评价
const doctorAppraise = (params) => {
	return axGet('v1/doctors/doctor-appraise', params);
};

export {
	departmentList,
	deptDiseaseList,
	searchDoctor,
	searchHistory,
	doctorDetail,
	doctorDetailBind,
	doctorAppraise,
	doctorRecommend
};
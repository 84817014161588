var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    attrs: { src: require("../../assets/images/no-data.png"), alt: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div>
		<div class="search b-w flex f-ai-c pr">
			<span class="input" @click="diseaseClick()">搜索医生、医院、疾病</span>
			<i></i>
		</div>
		<!-- 科室列表 -->
		<div class="dds b-w flex">
			<!-- 科室 -->
			<ul class="depts">
				<li
					:class="activeTab === item.id ? 'active' : ''"
					v-for="(item, index) in depts" :key="index"
					@click="deptClick(item.id)"
				>{{item.name}}</li>
			</ul>
			<!-- 疾病 -->
			<ul class="disease" v-if="disease.length">
				<li v-for="(item, index) in disease" :key="index" @click="diseaseClick(item.name)">{{item.name}}</li>
			</ul>
			<div class="no-data-div" v-else>
				<no-data></no-data>
			</div>
		</div>
	</div>
</template>
<script>
import { departmentList, deptDiseaseList } from '@/request/api/doctor';
import noData from '@/views/common/noData.vue';
export default {
	data () {
		return {
			depts: [],
			disease: [],
			activeTab: 0
		};
	},
	components: { noData },
	created () {
		this.getDeptList();
	},
	methods: {
		deptClick (id) {
			this.activeTab = id;
			deptDiseaseList({ deptId: id }).then(data => {
				if (data && data === 'retry') {
					this.deptClick(id);
				} else if (data && data.length) {
					this.disease = data;
				} else {
					this.disease = [];
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		getDeptList () {
			departmentList().then(data => {
				if (data && data === 'retry') {
					this.getDeptList();
				} else if (data && data.length) {
					this.depts = data;
					this.activeTab = data[0].id;
					this.deptClick(this.activeTab);
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		diseaseClick (name) {
			this.$root.go('/search', { diseaseName: name });
		}
	}
};
</script>
<style lang="less" scoped>
	.search {
		height: 49px;
		padding: 0 12px;
		margin-bottom: 6px;
		.input {
			width: 100%;
			height: 33px;
			line-height: 33px;
			background: #F3F4F6;
			border-radius: 16.5px;
			padding-left: 34px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #959595;
			outline: none;
			border: none;
		}
		i {
			content: '';
			background: url('../../assets/images/search-icon.png') no-repeat;
			background-size: contain;
			position: absolute;
			width: 14px;
			height: 14px;
			display: inline-block;
			left: 26px;
		}
	}
	.dds {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 55px;
		justify-content: space-between;
		.depts {
			width: 110px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #7D7D7D;
			background-color: #F7F7F7;
			overflow-y: scroll;
			display: block;
			li {
				// background-color: #F7F7F7;
				height: 50px;
				line-height: 50px;
				padding-left: 15px;
				&.active {
					background-color: white !important;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #262626;
				}
			}
		}
		.no-data-div {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				position: inherit;
				top: 0;
				left: 0;
				transform: inherit;
			}
		}
		.disease {
			padding-right: 15px;
			padding-left: 20px;
			// width: 300px;
			flex: 1;
			overflow-y: scroll;
			li {
				height: 50px;
				border-bottom: 1px solid #ECECEC;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #262626;
				line-height: 50px;
			}
		}
	}
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "search b-w flex f-ai-c pr" }, [
      _c(
        "span",
        {
          staticClass: "input",
          on: {
            click: function ($event) {
              return _vm.diseaseClick()
            },
          },
        },
        [_vm._v("搜索医生、医院、疾病")]
      ),
      _c("i"),
    ]),
    _c("div", { staticClass: "dds b-w flex" }, [
      _c(
        "ul",
        { staticClass: "depts" },
        _vm._l(_vm.depts, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              class: _vm.activeTab === item.id ? "active" : "",
              on: {
                click: function ($event) {
                  return _vm.deptClick(item.id)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm.disease.length
        ? _c(
            "ul",
            { staticClass: "disease" },
            _vm._l(_vm.disease, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.diseaseClick(item.name)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            0
          )
        : _c("div", { staticClass: "no-data-div" }, [_c("no-data")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }